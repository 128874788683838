import { createElement } from "react";
import { createRoot } from "react-dom/client";

/**
 * Render a component to an id, if available
 * @param Component
 * @param to target id
 */
const renderComponent = (Component, to) => {
	let rendered = false;

	const render = () => {
		const element = document.getElementById(to);

		if (element && !rendered) {
			let props = {};

			if (Object.prototype.hasOwnProperty.call(element.attributes, "data-extra-props")) {
				try {
					props = JSON.parse(element.attributes["data-extra-props"].value);
				} catch (e) {
					console.error(`Could not read props of ${to}`);
				}
			}
			rendered = true;

			createRoot(element).render(createElement(Component, props));
		}
	};

	document.addEventListener("DOMContentLoaded", render, { once: true });
	render();
};

const renderComponentsToClassname = (Component, to) => {
	let nrRendered = 0;

	const render = () => {
		const elements = document.getElementsByClassName(to);
		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			if (element && nrRendered < elements.length) {
				let props = {};

				if (Object.prototype.hasOwnProperty.call(element.attributes, "data-extra-props")) {
					try {
						props = JSON.parse(element.attributes["data-extra-props"].value);
					} catch (e) {
						console.error(`Could not read props of ${to}`);
					}
				}
				nrRendered++;

				createRoot(element).render(createElement(Component, props));
			}
		}
	};

	window.addEventListener("load", render, { once: true });
	render();
};

export { renderComponent, renderComponentsToClassname };
